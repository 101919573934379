import { useEffect, useState } from 'react';
import BranchSDKContext from '../../context/branchSDKContext';

const BranchSDKProvider = ({ children }) => {
  const [branchSDKData, setBranchSDKData] = useState(null);
  const [branchSDK, setBranchSDK] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function initAndFetch() {
      const BranchSDK = (await import('branch-sdk')).default;
      setBranchSDK(BranchSDK);

      BranchSDK.init(process.env.NEXT_PUBLIC_BRANCH_IO_API_KEY, function (err) {
        if (err) {
          setError(err);
          return;
        }
      });

      BranchSDK.data(function (err, data) {
        setBranchSDKData(data);
        if (err) {
          setError(err);
          return;
        }
      });
    }

    initAndFetch();
  }, []);

  return (
    <BranchSDKContext.Provider
      value={{ branchSDKData, setBranchSDKData, branchSDK, error }}
    >
      {children}
    </BranchSDKContext.Provider>
  );
};

export default BranchSDKProvider;
