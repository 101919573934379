import { useEffect, useCallback } from 'react';

const useOutsideClick = (ref, action, isEnabled = true) => {
  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        action(event);
      }
    },
    [ref, action]
  );

  const removeListener = () =>
    document.removeEventListener('mousedown', handleClickOutside);

  useEffect(() => {
    if (isEnabled) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      removeListener();
    }

    return removeListener;
  }, [ref, isEnabled]);
};

export default useOutsideClick;
