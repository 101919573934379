export const getUserTrackingData = ({
  id,
  email,
  firstName,
  lastName,
  uid,
  uuid,
}) => ({
  id,
  email,
  firstName,
  lastName,
  uid,
  uuid,
});
