import { useState } from 'react';
import { useTranslations } from 'next-intl';
import { signIn } from 'next-auth/client';

import SERVICES from 'constants/services';
import httpClient from 'httpClient';
import { AUTH_HEADERS } from 'constants';

const useUserInformation = () => {
  const [data, setData] = useState();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const errorsTranslations = useTranslations('Errors');

  const updatePassword = async (headers, passwordValues) => {
    setIsLoading(true);
    setError('');
    try {
      const { data } = await httpClient.request({
        url: SERVICES.password,
        method: 'PUT',
        data: {
          password: passwordValues.password,
          password_confirmation: passwordValues.confirmPassword,
        },
        headers: headers,
      });
      setData(data);
    } catch (error) {
      if (error.response?.data?.errors[0])
        setError(error.response.data.errors[0]);
      else if (error.response?.data?.error) setError(error.response.data.error);
      else setError(errorsTranslations('default'));
    } finally {
      setIsLoading(false);
    }
  };

  const updateUser = async (headers, data) => {
    setIsLoading(true);
    setError('');

    try {
      await signIn('credentials', {
        ...data,
        accessToken: headers[AUTH_HEADERS.accessToken],
        uid: headers[AUTH_HEADERS.uid],
        client: headers[AUTH_HEADERS.client],
        redirect: false,
      });
      setData({ isSuccess: true });
    } catch (error) {
      if (error.response?.data?.errors[0]) {
        setError(error.response.data.errors[0]);
      } else if (error.response?.data?.error) {
        setError(error.response.data.error);
      } else {
        setError(errorsTranslations('default'));
      }
    } finally {
      setIsLoading(false);
      setData(null);
    }
  };
  return {
    isLoading,
    error,
    setError,
    isSuccess: Boolean(data),
    data,
    setData,
    updatePassword,
    updateUser,
  };
};

export default useUserInformation;
