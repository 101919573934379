import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTracking } from 'react-tracking';

import { track, startTracking } from 'utils/tracking';

const TrackingProvider = ({ children }) => {
  const router = useRouter();
  let { pathname } = router;

  // process() is called on every useTracking hook call, with the current state of all tracking data.
  // If it returns a value, the result is merged with the tracking state and dispatched.
  const process = (payload) => {
    // If the data had `page`, dispatch a Page View event; see https://github.com/nytimes/react-tracking#top-level-optionsprocess
    if (payload.page) {
      return {
        event: 'Page View',
        pathname,
      };
    }
    return null;
  };

  const { Track } = useTracking(
    {},
    {
      dispatch: track,
      process,
    }
  );

  // Update `pathname` when the router changes; `process` will dispatch the final result once the page has mounted.
  const handleRouteChange = (url) => (pathname = url);

  useEffect(() => {
    if (!!navigator) {
      // Initialize the trackers
      startTracking();

      // Subscribe to router events
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, []);
  return <Track>{children}</Track>;
};

export default TrackingProvider;
