import { useCallback, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';

import brandsMap from 'utils/brandsMap';

const BRAND_ID_QUERY_PARAM = 'brand_id';
const EXTERNAL_ID_QUERY_PARAM = 'external_id';
const BRAND_ID_FIRST_PARAM_REGEX = new RegExp(/\?brand_id=[0-9]+/);
const BRAND_ID_NOT_FIRST_PARAM_REGEX = new RegExp(/&brand_id=[0-9]+/);
const EXTERNAL_ID_FIRST_PARAM_REGEX = new RegExp(/\?external_id=[a-zA-Z]+/);
const EXTERNAL_ID_NOT_FIRST_PARAM_REGEX = new RegExp(/&external_id=[a-zA-Z]+/);

const removeParamFromPath = (path, firstParamRgx, notFirstParamRgx) => {
  if (firstParamRgx.test(path)) {
    path = path.replace(firstParamRgx, '').replace('&', '?');
  } else if (notFirstParamRgx.test(path)) {
    path = path.replace(notFirstParamRgx, '');
  }

  return path;
};

const useBrandParam = () => {
  const { query, replace, asPath } = useRouter();
  let previousQuery = useRef({});

  const externalIdParamValue = query[EXTERNAL_ID_QUERY_PARAM];
  const brandIdParamValue = query[BRAND_ID_QUERY_PARAM];

  const clearParam = useCallback(() => {
    let path = asPath;
    path = removeParamFromPath(
      path,
      BRAND_ID_FIRST_PARAM_REGEX,
      BRAND_ID_NOT_FIRST_PARAM_REGEX
    );
    path = removeParamFromPath(
      path,
      EXTERNAL_ID_FIRST_PARAM_REGEX,
      EXTERNAL_ID_NOT_FIRST_PARAM_REGEX
    );
    replace(path, undefined, { shallow: true });
  }, [asPath, replace]);

  const externalId = useMemo(() => {
    const doesBrandExist = brandsMap.has(externalIdParamValue);

    return doesBrandExist ? externalIdParamValue : undefined;
  }, [externalIdParamValue]);

  // Set all query parameters into the page URL
  // 'params' expects a { name: value } object
  const setParams = useCallback(
    (params) => {
      let queryParams = query.redirectTo
        ? { ...query }
        : { ...query, ...params };
      // Remove any params with empty values
      queryParams = Object.fromEntries(
        Object.entries(queryParams).filter(([_, v]) => v != null)
      );

      // Do not apply if matches existing, but do apply if it has changed from previous state
      if (
        JSON.stringify(queryParams) !== JSON.stringify(query) ||
        JSON.stringify(previousQuery.current) !== JSON.stringify(queryParams)
      ) {
        // Update the existing URL with query params
        replace({ query: { ...queryParams } }, undefined, {
          options: { shallow: true },
        });
        previousQuery.current = queryParams;
      }
    },
    [replace, query]
  );

  return { externalId, brandId: brandIdParamValue, clearParam, setParams };
};

export default useBrandParam;
