import { useState, useCallback, useEffect } from 'react';

const parseFilters = (filters) => {
  const parsedFilters = {};

  Object.keys(filters).forEach((key) => {
    if (filters[key] instanceof Map) {
      parsedFilters[key] = Array.from(filters[key]?.keys());
    } else {
      parsedFilters[key] = filters[key];
    }
  });

  return parsedFilters;
};

const useClassesFilters = (
  currentBrandId,
  closeSidebar,
  trackEvent,
  filterInitialState = {}
) => {
  const [filters, setFilters] = useState(filterInitialState);
  const [filtersState, setFiltersState] = useState(filterInitialState);

  const applyFilters = () => {
    setFilters(parseFilters(filtersState));
    closeSidebar?.();
    trackEvent?.({
      event: 'Filter On Demand',
      filters: parseFilters(filtersState),
    });
  };

  const resetFilters = useCallback(() => {
    setFilters({});
    setFiltersState({});
    closeSidebar?.();
    trackEvent({ event: 'Filter On Demand', filters: parseFilters({}) });
  }, []);

  const setter = useCallback(
    (filterType, hasMultipleValues = true) =>
      (id) => {
        setFiltersState((prevState) => {
          let filterState = hasMultipleValues && new Map(prevState[filterType]);

          if (hasMultipleValues) {
            if (filterState?.has(id)) filterState.delete(id);
            else filterState.set(id, true);

            if (!filterState.size) filterState = undefined;
          }

          return {
            ...prevState,
            [filterType]: hasMultipleValues ? filterState : id,
          };
        });
      },
    []
  );

  useEffect(() => {
    if (typeof currentBrandId === 'number') {
      setFilters(({ modalities_ids, ...restFilters }) => restFilters);
      setFiltersState(({ modalities_ids, ...restFilters }) => restFilters);
    }
  }, [currentBrandId]);

  return {
    filters,
    filtersState,
    setter,
    applyFilters,
    resetFilters,
  };
};

export default useClassesFilters;
