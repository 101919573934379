import { useState, useCallback } from 'react';
import { useSession } from 'next-auth/client';

import httpClient from 'httpClient';
import SERVICES from 'constants/services';

const useGetEntitlement = (translations) => {
  const [session] = useSession();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const getEntitlement = useCallback(
    async (id) => {
      setError();
      setData();
      setIsLoading(true);
      try {
        const { data, error: requestError } = await httpClient.request({
          headers: {
            'access-token': session.user.accessToken,
            client: session.user.client,
            uid: session.user.uid,
          },
          url: `${SERVICES.subscriptionEntitlement}/${id}`,
          method: 'POST',
        });
        setData(data);
        if (requestError || !data.success) setError(requestError.message);
      } catch (err) {
        setError(translations('default'));
      } finally {
        setIsLoading(false);
      }
    },
    [translations]
  );

  return { getEntitlement, isLoading, error, data };
};

export default useGetEntitlement;
