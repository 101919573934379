import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';

import SERVICES from 'constants/services';
import { ROUTES } from 'constants/routePaths';
import { useLinkedAccountsContext } from 'hooks';
import httpClient from 'httpClient';
import {
  setFoundAccounts,
  setLinkedAccountEmail,
} from 'state/actions/creators/linkedAccountsActions';
import { transferQueryParams } from 'utils/carryOverQueryParams';

const useEmailLookup = ({
  onAdditionalEmailFlow = false,
  defaultErrorMessage = '',
  notMatchingEmailMessage = '',
}) => {
  const router = useRouter();

  const { dispatch: linkedAccountDispatch } = useLinkedAccountsContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onLookUp = useCallback(
    async (emailValue) => {
      setError('');
      setIsLoading(true);

      try {
        const { data, error: requestError } = await httpClient.request({
          url: onAdditionalEmailFlow
            ? SERVICES.userLookups
            : SERVICES.verifiedUserLookups,
          method: 'POST',
          data: {
            email: emailValue,
            referrer_id: router.query.referrer_id,
            format: 'json',
          },
        });

        if (!requestError) {
          const email = data.userLookups?.email;
          linkedAccountDispatch(setLinkedAccountEmail(email));

          // If the referrer ID is not verified, remove it from the query params
          if (!data.userLookups.referrerIdExists && router.query.referrer_id)
            delete router.query.referrer_id;

          if (!data.userLookups.count) {
            if (onAdditionalEmailFlow) {
              setError(new Error(notMatchingEmailMessage));
              setIsLoading(false);
            } else {
              router.push({
                pathname: ROUTES.signupInformation,
                query: router.query,
              });
            }
          } else {
            linkedAccountDispatch(
              setFoundAccounts(
                data.userLookups?.users?.map(
                  ({ id, email, studio, brand }) => ({
                    id,
                    email,
                    studio: {
                      studioId: studio?.id,
                      name: studio?.name,
                    },
                    brandId: brand?.externalId,
                  })
                )
              )
            );

            router.push(
              transferQueryParams(router.asPath, ROUTES.matchingAccount)
            );
          }
        } else {
          setError(requestError);
          setIsLoading(false);
        }
      } catch (err) {
        if (err?.response?.status === 422) {
          const errors = err.response.data.errors;

          setError(errors);
        } else {
          setError(new Error(defaultErrorMessage));
        }

        setIsLoading(false);
      }
    },
    [
      defaultErrorMessage,
      linkedAccountDispatch,
      notMatchingEmailMessage,
      onAdditionalEmailFlow,
      router,
    ]
  );

  return { onLookUp, isLoading, error };
};

export default useEmailLookup;
