import { useRouter } from 'next/router';

import { checkAdditionalEmailFlow } from 'utils';

const useAdditionalEmailFlow = () => {
  const router = useRouter();

  return checkAdditionalEmailFlow(router?.query?.flow);
};

export default useAdditionalEmailFlow;
