import { useState, useEffect } from 'react';
import { useSWR } from 'hooks';
import { useRouter } from 'next/router';

import currencies from 'utils/currencyMap';
import SERVICES from 'constants/services';

const useCurrency = (plans) => {
  const { data, error, isLoading } = useSWR(SERVICES.paymentProfile);
  const [currency, setCurrency] = useState('usd');
  const { locale } = useRouter();
  const localeCurrency = currencies.get(locale.split('-').join(''));

  useEffect(() => {
    // If client has previous purchases - getting the default currency from stripe.
    if (data && data.stripeCustomer?.defaultCurrency) {
      setCurrency(data.stripeCustomer.defaultCurrency);
    }
    // If it is a first client's purchase and ALL the plans have locale currency options - we set the locale currency.
    else if (
      plans &&
      plans.length > 0 &&
      plans.filter(
        (plan) =>
          plan.currencyOptions &&
          plan.currencyOptions.some(
            (x) => x.currency === localeCurrency.currency
          )
      ).length === plans.length
    ) {
      setCurrency(localeCurrency.currency);
    }
    // Otherwise we use the default value 'usd'
  }, [data, plans, localeCurrency]);

  return { currency, isLoading, error };
};

export default useCurrency;
