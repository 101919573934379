import httpClient from 'httpClient';
import { AUTH_HEADERS } from 'constants/index';

const fetcher = async (url, accessToken, client, uid) => {
  const config = {
    url,
    headers: {
      [AUTH_HEADERS.accessToken]: accessToken,
      client,
      uid,
    },
  };

  const { data } = await httpClient
    .request(config)
    .catch(function ({ response, message }) {
      const error = new Error('An error occurred while fetching the data.');
      error.info = message;
      error.status = response?.status;
      error.isOnline = navigator.onLine;
      error.data = response?.data;
      throw error;
    });

  return data;
};

export default fetcher;
