/* eslint-disable react/forbid-prop-types */
import React, { useRef, useEffect } from 'react';
import cn from 'classnames/bind';
import Loader from 'react-loader-spinner';
import { any, bool, func, node, object, oneOfType, string } from 'prop-types';

import {
  BUTTON_SORT,
  BUTTON_SIZE,
  BUTTON_LOADER_SIZE,
  BUTTON_LOADER_COLOR,
} from 'constants/buttonConstants';
import getComponents from 'utils/getComponents';
import brands from 'utils/brandsMap';
import defaultBrandInfo from 'constants/defaultBrandInfo';

import styles from 'styles/components/button.module.scss';

const style = cn.bind(styles);

const defaultComponents = {
  ButtonElem: 'button',
  Label: 'span',
};

const Button = ({
  icon: Icon,
  children,
  onClick,
  sort = BUTTON_SORT.primary,
  label,
  size,
  overrides,
  disabled = false,
  isLoading = false,
  brandId,
}) => {
  const ref = useRef();
  const {
    ButtonElem: { component: ButtonElem, props: buttonProps },
    Label: { component: Label, props: LabelProps },
  } = getComponents(defaultComponents, overrides);

  const btnClassName = style({
    btn: true,
    secondaryBtn: sort === BUTTON_SORT.secondary,
    secondaryFloatingBtn: sort === BUTTON_SORT.floatingSecondary,
    secondaryGhostBtn: sort === BUTTON_SORT.ghostSecondary,
    smallSize: size === BUTTON_SIZE.small,
    mediumSize: size === BUTTON_SIZE.medium,
    disabled: disabled && !isLoading,
    loading: isLoading,
  });

  // loader size fallback
  const loaderSize =
    BUTTON_LOADER_SIZE.get(size) || BUTTON_LOADER_SIZE.get(BUTTON_SIZE.medium);

  useEffect(() => {
    ref.current && ref.current.style.setProperty('--main-color', '');
    ref.current && ref.current.style.setProperty('--main-text-color', '');
    ref.current && ref.current.style.setProperty('--secondary-text-color', '');
    if (typeof brandId === 'string' && ref.current) {
      const brandData = brands.get(brandId) || defaultBrandInfo;
      ref.current.style.setProperty('--main-color', brandData.mainColor);
      if (brandData.mainTextColor !== brandData.mainColor) {
        ref.current.style.setProperty(
          '--main-text-color',
          brandData.mainTextColor
        );
      }
      brandData.secondaryTextColor &&
        ref.current.style.setProperty(
          '--secondary-text-color',
          brandData.secondaryTextColor
        );
    }
  }, [brandId]);

  return (
    <ButtonElem
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
      className={`${btnClassName} ${buttonProps.className}`}
      ref={ref}
    >
      {isLoading ? (
        <Loader
          type="TailSpin"
          color={BUTTON_LOADER_COLOR.get(sort)}
          height={loaderSize}
          width={loaderSize}
        />
      ) : (
        <>
          {Icon && <Icon className={styles.icon} />}
          {label && (
            <Label
              {...LabelProps}
              className={`${styles.label} btn-semibold-14 ${LabelProps.className}`}
            >
              {label}
            </Label>
          )}
          {children}
        </>
      )}
    </ButtonElem>
  );
};

Button.propTypes = {
  children: node,
  onClick: func,
  className: oneOfType([string, object]),
  overrides: object,
  icon: any,
  disabled: bool,
  isLoading: bool,
};

export default Button;
