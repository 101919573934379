import { useState, useRef, useCallback } from 'react';

import styles from 'styles/components/image-uploader.module.scss';

const useUploadImage = () => {
  const [image, setImage] = useState();
  const inputRef = useRef();

  const clearImage = () => setImage(null);
  const onInputChange = (e) => {
    const selectedImage = e.target.files[0];
    selectedImage && setImage(URL.createObjectURL(selectedImage));
  };
  const clickInput = () => inputRef?.current?.click();

  const ImageUploader = useCallback(({ onChange }) => {
    const handleOnChange = (e) => {
      onChange && onChange(e);
      onInputChange(e);
    };
    return (
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={handleOnChange}
        className={styles.input}
      />
    );
  }, []);

  return { ImageUploader, clickInput, image, clearImage };
};

export default useUploadImage;
