import { v4 as uuidv4 } from 'uuid';
import { DEVICE_ID_KEY } from '../tracking';

// XPlus device ID; this is for our internal tracking
export const deviceId = () => {
  let deviceId = localStorage.getItem(DEVICE_ID_KEY);
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem(DEVICE_ID_KEY, deviceId);
  }
  return deviceId;
};
