import {
  SET_CANCEL_PLAN_MODAL,
  SET_SIGNUP_MODAL,
  SET_HEADER_ON_CLOSE_FUNCTION,
} from './actions/types/uiActionTypes';

export const initialState = {
  modal: {
    signUpBackModal: false,
    cancelPlanModal: false,
  },
  header: {
    onClose: null,
  },
};

const uiReducer = (state, action) => {
  switch (action.type) {
    case SET_SIGNUP_MODAL:
      return {
        ...state,
        modal: { ...state.modal, signUpBackModal: action.signUpBackModal },
      };
    case SET_CANCEL_PLAN_MODAL:
      return {
        ...state,
        modal: { ...state.modal, cancelPlanModal: action.cancelPlanModal },
      };
    case SET_HEADER_ON_CLOSE_FUNCTION:
      return {
        ...state,
        header: {
          ...state.header,
          onClose: action.func,
        },
      };
    default:
      throw new Error();
  }
};

export default uiReducer;
