import SVGIcons from 'assets';

const brands = new Map([
  [
    'cyclebar',
    {
      id: 1,
      friendlyName: 'CycleBar',
      workoutType: 'Cycling',
      mainColor: '#BF1616',
      mainTextColor: '#ffffff',
      secondaryTextColor: '#BF1616',
      logo: SVGIcons.brandsLogo.CycleBarLogo,
      squareLogo: SVGIcons.brandsSquareLogo.CycleBarSquareLogo,
      whiteLogo: SVGIcons.brandsLogo.CycleBarLogo,
      icon: SVGIcons.brandIcon.CycleBarIcon,
    },
  ],
  [
    'rowhouse',
    {
      id: 2,
      friendlyName: 'Row House',
      workoutType: 'Rowing',
      mainColor: '#FFCD00',
      mainTextColor: '#000000',
      secondaryTextColor: '#000000',
      logo: SVGIcons.brandsLogo.RowHouseLogo,
      squareLogo: SVGIcons.brandsSquareLogo.RowHouseSquareLogo,
      whiteLogo: SVGIcons.brandsLogoWhite.WhiteRowHouseLogo,
      icon: SVGIcons.brandIcon.RowHouseIcon,
    },
  ],
  [
    'purebarre',
    {
      id: 3,
      friendlyName: 'Pure Barre',
      workoutType: 'Barre',
      mainColor: '#CB333B',
      mainTextColor: '#ffffff',
      secondaryTextColor: '#CB333B',
      logo: SVGIcons.brandsLogo.PureBarreLogo,
      squareLogo: SVGIcons.brandsSquareLogo.PureBarreSquareLogo,
      whiteLogo: SVGIcons.brandsLogoWhite.WhitePureBarreLogo,
      icon: SVGIcons.brandIcon.PureBarreIcon,
    },
  ],
  [
    'yogasix',
    {
      id: 4,
      friendlyName: 'YogaSix',
      workoutType: 'Yoga',
      mainColor: '#005776',
      mainTextColor: '#ffffff',
      secondaryTextColor: '#005776',
      logo: SVGIcons.brandsLogo.YogaSixLogo,
      squareLogo: SVGIcons.brandsSquareLogo.YogaSquareLogo,
      whiteLogo: SVGIcons.brandsLogoWhite.WhiteYogaSixLogo,
      icon: SVGIcons.brandIcon.YogaSixIcon,
    },
  ],
  [
    'akt',
    {
      id: 5,
      friendlyName: 'AKT',
      workoutType: 'Dance',
      mainColor: '#4D008C',
      mainTextColor: '#ffffff',
      secondaryTextColor: '#4D008C',
      logo: SVGIcons.brandsLogo.AktLogo,
      squareLogo: SVGIcons.brandsSquareLogo.AktSquareLogo,
      whiteLogo: SVGIcons.brandsLogoWhite.WhiteAktLogo,
      icon: SVGIcons.brandIcon.AktIcon,
    },
  ],
  [
    'clubpilates',
    {
      id: 6,
      friendlyName: 'Club Pilates',
      workoutType: 'Pilates',
      mainColor: '#5C8CC9',
      mainTextColor: '#ffffff',
      secondaryTextColor: '#5C8CC9',
      logo: SVGIcons.brandsLogo.ClubPilatesLogo,
      squareLogo: SVGIcons.brandsSquareLogo.ClubPilatesSquareLogo,
      whiteLogo: SVGIcons.brandsLogoWhite.WhiteClubPilatesLogo,
      icon: SVGIcons.brandIcon.ClubPilatesIcon,
    },
  ],
  [
    'stretchlab',
    {
      id: 7,
      friendlyName: 'StretchLab',
      workoutType: 'Stretching',
      mainColor: '#12ADBB',
      mainTextColor: '#ffffff',
      secondaryTextColor: '#12ADBB',
      logo: SVGIcons.brandsLogo.StretchLabLogo,
      squareLogo: SVGIcons.brandsSquareLogo.StretchLabSquareLogo,
      whiteLogo: SVGIcons.brandsLogoWhite.WhiteStretchLabLogo,
      icon: SVGIcons.brandIcon.StretchLabIcon,
    },
  ],
  [
    'stride',
    {
      id: 8,
      friendlyName: 'STRIDE',
      workoutType: 'Running',
      mainColor: '#DB0A5B',
      mainTextColor: '#ffffff',
      secondaryTextColor: '#DB0A5B',
      logo: SVGIcons.brandsLogo.StrideLogo,
      squareLogo: SVGIcons.brandsSquareLogo.StrideSquareLogo,
      whiteLogo: SVGIcons.brandsLogoWhite.WhiteStrideLogo,
      icon: SVGIcons.brandIcon.StrideIcon,
    },
  ],
  [
    'rumble',
    {
      id: 9,
      friendlyName: 'Rumble',
      workoutType: 'Boxing',
      mainColor: '#D50032',
      mainTextColor: '#ffffff',
      secondaryTextColor: '#D50032',
      logo: SVGIcons.brandsLogo.RumbleLogo,
      squareLogo: SVGIcons.brandsSquareLogo.RumbleSquareLogo,
      whiteLogo: SVGIcons.brandsLogoWhite.WhiteRumbleLogo,
      icon: SVGIcons.brandIcon.RumbleIcon,
    },
  ],
  [
    'bft',
    {
      id: 12,
      friendlyName: 'BFT',
      workoutType: 'Strength',
      mainColor: '#03B1C7',
      mainTextColor: '#ffffff',
      secondaryTextColor: '#03B1C7',
      logo: SVGIcons.brandsLogo.BftLogo,
      squareLogo: SVGIcons.brandsSquareLogo.BftSquareLogo,
      whiteLogo: SVGIcons.brandsLogoWhite.WhiteBftLogo,
      icon: SVGIcons.brandIcon.BftIcon,
    },
  ],
  [
    'kinrgy',
    {
      id: 24,
      friendlyName: 'Kinrgy',
      workoutType: 'Dance',
      mainColor: '#958D82',
      mainTextColor: '#ffffff',
      secondaryTextColor: '#958D82',
      logo: SVGIcons.brandsLogo.KinrgyLogo,
      squareLogo: SVGIcons.brandsSquareLogo.KinrgySquareLogo,
      whiteLogo: SVGIcons.brandsLogoWhite.KinrgyLogo,
      icon: SVGIcons.brandIcon.KinrgyIcon,
    },
  ],
  [
    'lindora',
    {
      id: 24,
      friendlyName: 'Lindora',
      workoutType: 'Wellness',
      mainColor: '#36436B',
      mainTextColor: '#ffffff',
      secondaryTextColor: '#36436B',
      logo: SVGIcons.brandsLogo.LindoraLogo,
      squareLogo: SVGIcons.brandsSquareLogo.LindoraSquareLogo,
      whiteLogo: SVGIcons.brandsLogoWhite.LindoraLogo,
      icon: SVGIcons.brandIcon.LindoraIcon,
    },
  ],
]);

export default brands;
