import { useContext } from 'react';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';

import { ROUTES } from 'constants/routePaths';
import { REDIRECT_KEYS } from 'constants/';
import { transferQueryParams } from 'utils/carryOverQueryParams';

import getUrlWithParams from 'utils/getUrlWithParams';

import BrandContext from 'context/brandContext';
import { setBrandId } from 'state/actions/creators/brandActions';
import brandsMap from 'utils/brandsMap';

const useCheckRedirections = (initialParams = {}) => {
  const router = useRouter();
  const params = { ...router.query, ...initialParams };
  const { dispatch } = useContext(BrandContext);

  const checkRedirections = () => {
    const {
      [REDIRECT_KEYS.index]: redirectTo,
      [REDIRECT_KEYS.signIn]: signIn,
      [REDIRECT_KEYS.purchase]: purchase,
      ...rest
    } = params;

    if (signIn && router.route === ROUTES.login) {
      router.push(getUrlWithParams(signIn, rest, '?'));
      return true;
    }
    if (redirectTo && !purchase) {
      checkPathForBrand(redirectTo);
      router.push(getUrlWithParams(redirectTo, rest, '&'));
      return true;
    } else return checkPrefilledPayment();
  };

  const checkPathForBrand = (path) => {
    // Get the query parts out of the redirect URL, then check if brand is a query param
    const queryParams = path.substring(path.indexOf('?') + 1).split('&');
    const brandQuery = queryParams.filter((q) => q.startsWith('brand='));

    if (Object.keys(brandQuery).length) {
      const externalId = brandQuery[0].split('=')[1];
      const { id } = brandsMap.get(externalId) || '';
      if (id) {
        // If there is a brand and it is valid, set the brand
        dispatch(setBrandId({ id: id, externalId: externalId }));
      }
    }
  };

  const checkPrefilledPayment = () => {
    if (params.store_id && params.sp) {
      // params are pulled from the route.
      if (isEmpty(initialParams)) {
        router.push(
          transferQueryParams(router.asPath, ROUTES.subscriptionPayment)
        );
        return true;
      }

      // params are pulled from any external source.
      router.push(getUrlWithParams(ROUTES.subscriptionPayment, params, '?'));
      return true;
    }

    return false;
  };

  return {
    checkRedirections,
    checkPrefilledPayment,
    transferQueryParams,
    checkPathForBrand,
  };
};

export default useCheckRedirections;
