import { useEffect, useReducer, useRef } from 'react';
import { useRouter } from 'next/dist/client/router';

import BrandContext from 'context/brandContext';
import { useBrandParam } from 'hooks';
import { setBrandId } from 'state/actions/creators/brandActions';
import brandReducer, { initialState } from 'state/brandReducer';
import brandsMap from 'utils/brandsMap';

const BrandProvider = ({ children }) => {
  const [state, dispatch] = useReducer(brandReducer, initialState);

  const { externalId, brandId, clearParam, setParams } = useBrandParam();
  const { query } = useRouter();
  let initialized = useRef(false);

  // Check on initial page load if a brand is in the query params
  useEffect(() => {
    if (query.brand && !initialized.current) {
      const externalId = query.brand;
      const { id } = brandsMap.get(externalId) || '';
      if (id) {
        dispatch(setBrandId({ id, externalId }));
      }
    }
    initialized.current = true;
  }, [query]);

  useEffect(() => {
    const isCurrentBrandId = state.id === brandId;
    const isCurrentExternalId = state.externalId === externalId;

    if (externalId && brandId && (!isCurrentBrandId || !isCurrentExternalId)) {
      dispatch(setBrandId({ id: brandId, externalId }));
      clearParam();
    }

    if (state.externalId !== query.brand) {
      setParams({ brand: state.externalId });
    }
  }, [
    state.externalId,
    externalId,
    state.brandId,
    state.id,
    brandId,
    clearParam,
    setParams,
    query,
  ]);

  return (
    <BrandContext.Provider
      value={{ id: state.id, externalId: state.externalId, dispatch }}
    >
      {children}
    </BrandContext.Provider>
  );
};

export default BrandProvider;
