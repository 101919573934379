const currency = new Map([
  [
    'enUS',
    {
      currency: 'usd',
    },
  ],
  [
    'enAU',
    {
      currency: 'aud',
    },
  ],
  [
    'enCA',
    {
      currency: 'cad',
    },
  ],
  [
    'en',
    {
      currency: 'usd',
    },
  ],
]);

export default currency;
