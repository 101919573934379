import { useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { useSession } from 'next-auth/client';

import getAuthHeadersFromSession from 'utils/auth/getAuthHeadersFromSession';

const useSWRInfiniteHook = (getKeyFromIndex, options) => {
  const [session, loading] = useSession();
  const config = useMemo(
    () => getAuthHeadersFromSession(session),
    /* eslint-disable-next-line react-hooks/exhaustive-deps*/
    [session?.user?.accessToken]
  );

  /*
    - No query should be performed if session is still loading.
    - useSWRInfinite key must be a function which receive index as a parameter, so
      index is passed down to getKeyFromIndex.
      Resulting key will be the value returned by getKeyFromIndex along auth headers.
  */
  return useSWRInfinite(
    (index) => (loading ? null : [getKeyFromIndex(index), ...config]),
    options
  );
};

export default useSWRInfiniteHook;
