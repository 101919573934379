import { HOME_SUBPATH } from 'constants/routePaths/routePathLabels';
import dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import capitalize from 'lodash/capitalize';
dayjs.extend(require('dayjs/plugin/customParseFormat'));
dayjs.extend(duration);

export const clearQueryParams = (path) => path.split('?')[0];

export const getSubpathFromPath = (path, subPaths = []) => {
  const subpaths = extractSubpaths(clearQueryParams(path), subPaths);
  if (HOME_SUBPATH.get(path)) subpaths.push('/');
  return subpaths.reverse();
};

export const extractSubpaths = (path, subPaths = []) => {
  const index = path.lastIndexOf('/');
  if (index === -1) return subPaths;
  subPaths.push(path);

  const subPath = path.substring(0, index);
  const remainSubPaths = extractSubpaths(subPath, subPaths);

  return remainSubPaths;
};

export const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const formatToStandardTime = (time) => {
  return dayjs(time, 'hh:mm').format('h:mma');
};

export const formatToShortDate = (time) => {
  return dayjs(time).format('MMM D');
};

export const formatToLongDateWithDay = (time) =>
  dayjs(time).format('dddd M/DD/YYYY');

export const customFormat = (format, date = dayjs()) => {
  return dayjs(date).format(format);
};

export const getUnixTimeStamp = (date) => {
  return dayjs(date).valueOf();
};

export const formatDateString = (dateTime) => {
  const day = dayjs(dateTime).format('ddd');

  const dateString = dayjs(dateTime).format('M/D/YYYY');

  const hourAndMinutes = formatToStandardTime(dayjs(dateTime));

  const date = `${day} ${dateString} `;

  const hour = ` ${hourAndMinutes}`;

  return [date, hour];
};

/**
 * Takes the a duration of a VOD or Livestream in seconds and returns the equivalent minutes from it.
 *
 * @param {number} sessionLength
 * @returns {number}
 */
export const getDurationAsMinutes = (sessionLength) => {
  return Math.round(sessionLength / 60);
};

/**
 * Takes the event start date time and returns the difference between
 * now and the and the event start date in seconds.
 *
 * @param {number} eventStartDateTime
 * @returns {number}
 */
export const getElapsedTimeAsSeconds = (eventStartDateTime) => {
  const elapsedTime = parseInt(
    dayjs
      .duration(dayjs(new Date()).diff(dayjs(eventStartDateTime)))
      .asSeconds(),
    10
  );

  return elapsedTime;
};

export const buildFullName = (firstName, lastName) =>
  `${capitalize(firstName)} ${capitalize(lastName)}`;

export const stopPropagation = (event) => {
  event.stopPropagation();
};

export const isClassReadyToStart = (startDateTime) => {
  const diffMs = new Date(startDateTime) - new Date();

  return diffMs <= 0;
};

export const checkAdditionalEmailFlow = (value) => value === 'additional-email';
