import { SET_BRAND_ID } from './actions/types/brandActionTypes';

export const initialState = { id: null, externalId: null };

const brandReducer = (state, action) => {
  switch (action.type) {
    case SET_BRAND_ID:
      return { id: action.id, externalId: action.externalId };
    default:
      throw new Error();
  }
};

export default brandReducer;
