const MSecs = 1000;
const Secs = 60;
const Mins = 60;
const Hrs = 24;

const daysSince = (isoDate) => {
  const now = new Date();
  const since = new Date(isoDate);

  return Math.floor((now - since) / MSecs / Secs / Mins / Hrs);
};

export const ampPayloadFor = (event, payload) => {
  if (event === 'Sign Up') {
    return {
      'Registration Type': payload.registrationType,
      Offer: null,
    };
  }

  if (event === 'Sign In') {
    return {
      'Sign In Type': payload.signInType,
    };
  }

  if (event === 'View Video Detail') {
    return {
      'Video ID': payload.id,
      Brand: payload.brandData.name,
      Page: payload.page,
    };
  }

  if (event === 'View Live Class Detail') {
    return {
      'Video ID': payload.id,
      Brand: payload.brandData.name,
      Page: payload.page,
    };
  }

  if (
    event === 'View Video' ||
    event === 'Video Complete 25' ||
    event === 'Video Complete 100'
  ) {
    return {
      'Video ID': payload.id,
      'Trainer ID': payload.instructor?.id,
      'Trainer Name': [
        payload.instructor?.firstName,
        payload.instructor?.lastName,
      ].join(' '),
      'Video Name': payload.title,
      Page: payload.page,
      Completion: payload.completion,
      Brand: payload.brandData.name,
      // TODO: Find a way to collect these
      // "Collection ID": "",
      // "Program ID": "",
    };
  }

  if (event === 'Page View') {
    return {
      Page: payload.page,
      Pathname: payload.pathname,
      ID: payload.id,
    };
  }

  if (event === 'View Live Schedule') {
    return {
      'Trainer IDs': _.map(
        payload.liveClasses,
        (liveClass) => liveClass.instructor?.id
      ),
      'Trainer Names': _.map(payload.liveClasses, (liveClass) =>
        [liveClass.instructor?.firstName, liveClass.instructor?.lastName].join(
          ' '
        )
      ),
    };
  }

  if (event === 'Select Date') {
    return {
      Date: payload.item?.label,
    };
  }

  if (event === 'Booked Live Class') {
    return {
      'Live Session ID': payload.id,
      'Session Price': null,
      'Price Paid': null,
      'Discount Amount': null,
      Page: payload.page,
      Trial: null,
      'Trainer ID': payload.instructor?.id,
      'Trainer Name': [
        payload.instructor?.firstName,
        payload.instructor?.lastName,
      ].join(' '),
      'Session Duration': payload.sessionLength,
      'Day of the Week': new Date(payload.startDateTime).wday,
      'Class Type': _.map(payload.modalities, (mode) => mode.name),
      'Live Session ID': payload.id,
      'Payment Type': null,
      'Book Ahead Time':
        (new Date(payload.startDateTime) - new Date()) / MSecs / Secs / Mins,
      'Session Mode': null,
      'Session Name': payload.sessionTitle,
    };
  }

  if (event === 'Attend Live Class') {
    return {
      'Live Session ID': payload.id,
      Page: payload.page,
      Trial: null,
      'Trainer ID': payload.instructor?.id,
      'Trainer Name': [
        payload.instructor?.firstName,
        payload.instructor?.lastName,
      ].join(' '),
      'Session Duration': payload.sessionLength,
      'Day of the Week': new Date(payload.startDateTime).wday,
      'Class Type': _.map(payload.modalities, (mode) => mode.name),
      'Live Session ID': payload.id,
      'Payment Type': null,
      'Book Ahead Time':
        (new Date(payload.startDateTime) - new Date()) / MSecs / Secs / Mins,
    };
  }

  if (event === 'Cancel Subscription') {
    return {
      'Subscription Plan ID': payload.subscription.id,
      'Attended Live Class': null,
      'Days Elapsed In Trial': payload.subscription.inTrial
        ? daysSince(payload.subscription.createdAt)
        : null,
      'Days Elapsed Since Subscription Date': daysSince(
        payload.subscription.createdAt
      ),
    };
  }

  if (event === 'Purchase Subscription') {
    return {
      'Subscription Plan ID': payload.subscriptionPayment,
      'Subscription Price': payload.price,
      'Subscription Package Name': payload.promoTitle,
    };
  }

  if (event === 'Filter On Demand') {
    return {
      Filters: payload.filters.modalities_ids,
    };
  }

  if (event === 'Click Link') {
    return {
      Event: 'Click Link',
      'Link Text': payload.title,
      URL: payload.url,
      'Promo Card': true,
    };
  }

  return false;
};
