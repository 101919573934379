import { useCallback } from 'react';
import { useSession } from 'next-auth/client';

import { AUTH_HEADERS, HTTP_METHODS } from 'constants';
import SERVICES from 'constants/services';
import httpClient from 'httpClient';

const useTrackVideoWatches = () => {
  const [session] = useSession();

  const trackWatches = useCallback(
    async ({ watchedSeconds, isLive, playbackStartedAt, videoId }) => {
      await httpClient.request({
        headers: {
          [AUTH_HEADERS.accessToken]: session?.user?.accessToken,
          [AUTH_HEADERS.client]: session?.user?.client,
          [AUTH_HEADERS.uid]: session?.user?.uid,
        },
        url: SERVICES.videoWatches,
        method: HTTP_METHODS.POST,
        data: {
          video_id: videoId,
          watched_seconds: watchedSeconds,
          live: isLive,
          playback_started_at: new Date(playbackStartedAt).toISOString(),
        },
      });
    },
    [session?.user]
  );

  return trackWatches;
};

export default useTrackVideoWatches;
