import { useCallback } from 'react';

import { useDeepLink, getPlatform } from './useDeepLink';

const usePromotionClick = () => {
  const { open } = useDeepLink();

  const onPromotionInternalClick = useCallback(
    (internalLinkUrls) => {
      const deepLink = {
        android: {
          appURL: internalLinkUrls.androidLink,
        },
        ios: {
          appURL: internalLinkUrls.iosLink,
        },
        web: {
          appURL: internalLinkUrls.webLink,
        },
      };

      const platform = getPlatform();

      const appURL = deepLink[platform].appURL;

      open(appURL);
    },
    [open]
  );

  const onClick = useCallback(
    (promotion) => () => {
      const useInternalDeepLink = promotion.useInternalDeepLink;

      if (useInternalDeepLink) {
        onPromotionInternalClick(promotion.internalLinkUrls);
      } else {
        window.open(offer.externalLinkUrl, '_blank');
      }
    },
    [onPromotionInternalClick]
  );

  return onClick;
};

export default usePromotionClick;
