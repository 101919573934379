import redirectToSignIn from 'utils/redirectToSignIn';

export const transferQueryParams = (url, newRoute) => {
  const queryParams = url.split('?');
  if (queryParams[2]) return `${newRoute}?${queryParams[1]}?${queryParams[2]}`;
  else if (queryParams[1]) return `${newRoute}?${queryParams[1]}`;

  return `${newRoute}?`;
};

const carryOverQueryParams = (context, newRoute) => {
  if (context.query.store_id) {
    return {
      redirect: {
        destination: transferQueryParams(context.resolvedUrl, newRoute),
        permanent: false,
      },
    };
  }

  return redirectToSignIn(context);
};

export default carryOverQueryParams;
