const getUrlWithParams = (url, params, delimiter) => {
  const queryParams = Object.keys(params)
    .map((key) =>
      params[key]
        ? encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        : null
    )
    .filter(Boolean)
    .join('&');

  return queryParams ? `${url}${delimiter}${queryParams}` : url;
};

export default getUrlWithParams;
