import { useState, useCallback, useEffect } from 'react';

import httpClient from 'httpClient';
import SERVICES from 'constants/services';

const useCheckEmail = (translations) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const sendCheckEmailCode = useCallback(
    async (email) => {
      setError();
      setData();
      setIsLoading(true);
      try {
        const { data, error: requestError } = await httpClient.request({
          url: SERVICES.checkEmailVerification,
          method: 'POST',
          data: {
            email: email,
            format: 'json',
          },
        });
        setData(data);
        if (requestError || !data.success) setError(requestError.message);
      } catch (err) {
        setError(translations('default'));
      } finally {
        setIsLoading(false);
      }
    },
    [translations]
  );

  return { sendCheckEmailCode, isLoading, error, data };
};

export default useCheckEmail;
