import { useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { useSession } from 'next-auth/client';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';

import { AUTH_HEADERS } from 'constants';
import SERVICES from 'constants/services';
import httpClient from 'httpClient';
import poll from 'utils/poll';

const usePayment = () => {
  const router = useRouter();
  const stripe = useStripe();
  const translations = useTranslations('Errors');
  const elements = useElements();
  const [session] = useSession();
  const user = session?.user;
  const [taxes, setTaxes] = useState(null);
  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState('');
  const [taxesError, setTaxesError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [areTaxesLoading, setAreTaxesLoading] = useState(false);
  const isStripeLoading = !stripe || !elements;
  const [stcid, setStcid] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [orderValue, setOrderValue] = useState(null);
  const [partialFailure, setPartialFailure] = useState(false); // Occurs when the new user was created successfully but the plan purchase failed
  const [userAuthData, setUserAuthData] = useState({});

  const estimateTaxes = async (
    address,
    sp,
    promoCodeId,
    urlOverride = null
  ) => {
    setAreTaxesLoading(true);
    let authHeaders = {
      [AUTH_HEADERS.accessToken]: user?.accessToken,
      [AUTH_HEADERS.client]: user?.client,
      [AUTH_HEADERS.uid]: user?.uid,
    };

    try {
      const { data } = await httpClient.request({
        headers: authHeaders,
        url: urlOverride
          ? urlOverride
          : `${SERVICES.subscriptionPlan}/${sp}/tax_estimates`,
        method: 'post',
        data: {
          new_cc: true,
          address,
          format: 'json',
          stripe_promotion_code_id: promoCodeId,
        },
      });

      if (data.messages) {
        setTaxesError(data.messages);
        setTaxes(null);
      } else {
        setTaxes(data.amount);
        setTaxesError('');
      }
    } catch (e) {
      setTaxesError(e.message);
      setTaxes(null);
    } finally {
      setAreTaxesLoading(false);
    }
  };

  const createPaymentMethod = async (values) => {
    setIsLoading(true);
    setError('');

    if (isStripeLoading) {
      // Stripe.js has not yet loaded.
      // Disabling form submission until Stripe.js has loaded.
      return;
    }

    const { city, state, country, address1, address2, zip } = values.address;

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        address: {
          city,
          country,
          line1: address1,
          line2: address2,
          postal_code: zip,
          state,
        },
        name: values.nameOnCard,
      },
    });

    if (error) {
      setError(error.message || translations('default'));
      setIsLoading(false);
      return null;
    }

    return paymentMethod;
  };

  const handleSubmit = async (values, authOverride = null) => {
    if (values.nameOnCard) {
      const paymentMethod = await createPaymentMethod(values);

      if (!paymentMethod) return;

      values.stripePaymentMethodId = paymentMethod.id;
    } else {
      setIsLoading(true);
    }

    createSubscription(values, authOverride);
  };

  const createSubscription = async (subInfo, authOverride = null) => {
    let authHeaders = {
      [AUTH_HEADERS.accessToken]: user?.accessToken,
      [AUTH_HEADERS.client]: user?.client,
      [AUTH_HEADERS.uid]: user?.uid,
    };

    // Generate click ID for Impact tracking
    await ire('generateClickId', (clickId) => {
      subInfo = { ...subInfo, impact_click_id: clickId };
    });

    try {
      const { data } = await httpClient.request({
        headers: authOverride ? authOverride : authHeaders,
        url: SERVICES.subscription,
        method: 'post',
        data: {
          subscription: subInfo,
          referrer_id: router.query.referrer_id || '',
          format: 'json',
        },
      });

      setStcid(data.subscription.stripeDetails.stripeCustomerId);
      setOrderId(data.subscription.id);
      setOrderValue(data.subscription.subscriptionPlan.price);

      if (data.subscription.status === 'active') {
        setSuccessful(true);

        return;
      }

      const actionResult = await handlePaymentThatRequiresCustomerAction(
        data.subscription
      );

      const userActionError =
        actionResult?.error?.message || actionResult?.message;

      if (userActionError) {
        setError(userActionError);
        setIsLoading(false);
        return;
      }

      await checkSubscriptionStatus(data.subscription.id, authHeaders);
    } catch (error) {
      setIsLoading(false);
      setError(error.response.data.message || translations('default'));
      setPartialFailure(true);
      setUserAuthData(authOverride);
    }
  };

  const handlePaymentThatRequiresCustomerAction = async (subscription) => {
    try {
      const result = await stripe.confirmCardPayment(
        subscription?.stripeDetails?.stripePaymentIntentClientSecret
      );

      return result;
    } catch (err) {
      return err;
    }
  };

  const checkSubscriptionStatus = async (
    subscriptionId,
    authOverride = null
  ) => {
    let authHeaders = {
      [AUTH_HEADERS.accessToken]: user?.accessToken,
      [AUTH_HEADERS.client]: user?.client,
      [AUTH_HEADERS.uid]: user?.uid,
    };
    try {
      const result = await poll({
        fn: async () => {
          try {
            return await httpClient.request({
              headers: authOverride ? authOverride : authHeaders,
              url: `${SERVICES.subscription}/${subscriptionId}`,
            });
          } catch (err) {
            return err;
          }
        },
        validate: (result) =>
          result?.data?.subscription?.status === 'active' || result.message,
        maxAttempts: 15,
      });

      // there was an error
      if (result.message) {
        setError(translations('default'));
        setIsLoading(false);
        return;
      }

      setSuccessful(true);
    } catch (error) {
      setError(translations('default'));
      setIsLoading(false);
    }
  };

  return {
    createSubscription,
    createPaymentMethod,
    error,
    setError,
    isLoading,
    areTaxesLoading,
    handleSubmit,
    successful,
    isStripeLoading,
    estimateTaxes,
    taxes,
    taxesError,
    stcid,
    orderId,
    orderValue,
    partialFailure,
    userAuthData,
  };
};

export default usePayment;
