import { useReducer } from 'react';

import LinkedAccountContext from 'context/linkedAccountsContext';
import linkedAccountsReducer, {
  initialState,
} from 'state/linkedAccountsReducer';

const LinkedAccountProvider = ({ children }) => {
  const [state, dispatch] = useReducer(linkedAccountsReducer, initialState);

  return (
    <LinkedAccountContext.Provider value={{ state, dispatch }}>
      {children}
    </LinkedAccountContext.Provider>
  );
};

export default LinkedAccountProvider;
