import { useCallback } from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';

import { useToggle } from 'hooks';
import Modal from 'components/common/Modal';

import styles from 'styles/components/social-media-share-modal.module.scss';

const SOCIAL_MEDIA_PROVIDERS = [
  {
    wrapper: FacebookShareButton,
    icon: FacebookIcon,
  },
  { wrapper: TwitterShareButton, icon: TwitterIcon },
];

const useSocialMediaShare = (host) => {
  const { isOn, toggle } = useToggle();
  const router = useRouter();
  const translations = useTranslations('SocialMediaShareModal');

  const SocialMediaShareModal = useCallback(
    () => (
      <Modal isOn={isOn} toggle={toggle}>
        <h3 className={`h2-bold-24 ${styles.title}`}>
          {translations('title')}
        </h3>
        <div className={styles.mediaContainer}>
          {SOCIAL_MEDIA_PROVIDERS.map(
            ({ wrapper: Component, icon: Icon }, index) => (
              <Component
                key={index}
                className={styles.socialButton}
                url={`${host}${router.asPath}`}
                size={60}
              >
                <Icon round />
              </Component>
            )
          )}
        </div>
      </Modal>
    ),
    [isOn, toggle, router.asPath, translations, host]
  );

  return { SocialMediaShareModal, toggle };
};

export default useSocialMediaShare;
