import {
  SET_EMAIL,
  SET_FOUND_ACCOUNTS,
  SET_ASSOCIATED_ACCOUNTS,
  SET_QUERY_PARAMS,
} from './actions/types/linkedAccountsActionTypes';

export const initialState = {
  email: null,
  foundAccounts: [],
  associatedAccounts: [],
  queryParams: {},
};

const linkedAccountsReducer = (state, action) => {
  switch (action.type) {
    case SET_EMAIL:
      return { ...state, email: action.email };
    case SET_FOUND_ACCOUNTS:
      return { ...state, foundAccounts: action.accounts };
    case SET_ASSOCIATED_ACCOUNTS:
      return { ...state, associatedAccounts: action.accounts };
    case SET_QUERY_PARAMS:
      return { ...state, queryParams: action.params };
    default:
      throw new Error();
  }
};

export default linkedAccountsReducer;
