import base64 from 'base-64';
import utf8 from 'utf8';
import httpClient from 'httpClient';
import amplitude from 'amplitude-js';
import TagManager from 'react-gtm-module';

import { getUserTrackingData } from 'utils/getUserData';
import { ampPayloadFor } from './tracking/ampPayloadFor';
import { deviceId } from './tracking/deviceId';

export const DEVICE_ID_KEY = 'xpt_v1';

// Amplitude device ID; this is managed by amplitude-js and may be used cross-domain
let ampDeviceId;

const encode = (str) => {
  const bytes = utf8.encode(str);
  return base64.encode(bytes);
};

const queue = [];
let running = false;

// Initialize Amplitude and anything else that needs one-time setup
export const startTracking = () => {
  if (!!process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
    const options = {
      deviceIdFromUrlParam: /amp_device_id/.test(location.search),
    };
    amplitude
      .getInstance()
      .init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, null, options);
  }

  if (!!process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    });
  }

  if (!!process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID) {
    // Hubspot event queue
    const script = document.createElement('script');
    script.src = `https://js.hs-scripts.com/${process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID}.js`;
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    document.getElementsByTagName('body')[0].appendChild(script);
  }

  if (!running) drainQueue();
  running = true;
};

const drainQueue = () => {
  queue.forEach((item) => processEvent(item));
};

export const track = (payload) => {
  if (running) {
    setTimeout(() => {
      processEvent(payload);
    }, 0);
  } else {
    queue.push(payload);
  }
};

export const identify = (user) => {
  if (!user) return;
  if (!!process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
    const amp = amplitude.getInstance();
    const trackedUser = getUserTrackingData(user);
    amp.setUserProperties(trackedUser);
    amp.setUserId(trackedUser.id);
  }

  if (!!process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID) {
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push([
      'identify',
      {
        email: user.email,
      },
    ]);
  }
};

export const anonymize = () => {
  if (!!process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
    amplitude.getInstance().setUserId(null); // not string 'null'
    amplitude.getInstance().regenerateDeviceId();
  }

  localStorage.removeItem(DEVICE_ID_KEY);
};

const processEvent = (payload) => {
  try {
    updateTrackers(payload);
  } catch (error) {}
};

const updateTrackers = (payload) => {
  if (!process.env.NEXT_PUBLIC_TRACKING_ENABLED) return;

  const { event, user } = payload;
  delete payload.event;
  delete payload.user;

  const env = process.env.NEXT_PUBLIC_ENVIRONMENT;
  const version = 0;
  const source = 'members.xponential.plus';
  const platform = 'web';

  const trackedUser = user ? getUserTrackingData(user) : {};
  const timestamp = new Date();
  const devid = deviceId();

  if (!!process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
    ampDeviceId = amplitude.getInstance().options.deviceId; // existing device id
  }

  const body = {
    platform,
    version,
    source,
    env,
    event,
    payload,
    devid,
    ampDeviceId,
    timestamp,
    user: trackedUser,
  };

  // data lake
  if (!!process.env.NEXT_PUBLIC_EVENT_TRACKING_URL) {
    httpClient
      .post(
        process.env.NEXT_PUBLIC_EVENT_TRACKING_URL,
        encode(JSON.stringify(body)),
        {
          headers: {
            'Content-Type': 'text/plain',
          },
        }
      )
      .catch((error) => {
        console.log(error);
      });
  }

  // GTM
  if (!!process.env.NEXT_PUBLIC_GTM_ID) {
    // initialize in case GTM has not started
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(body);
  }

  // amplitude
  if (!!process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
    const amp = amplitude.getInstance();

    try {
      const ampPayload = ampPayloadFor(event, payload);
      if (ampPayload) {
        amp.logEvent(event, ampPayload);
      }
    } catch (error) {}
  }

  // hubspot
  if (!!process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID) {
    var _hsq = (window._hsq = window._hsq || []);
    if (event === 'Page View') {
      _hsq.push(['setPath', payload.path]);
      _hsq.push(['trackPageView']);
    }
  }
};
