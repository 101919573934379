export const ENTER_KEY = 'Enter';

// App
export const RUNNING_SERVER_SIDE = typeof window === 'undefined';

// Language
export const DEFAULT_LANGUAGE = 'en';

// Timezone
export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Authorization
export const AUTH_HEADERS = {
  accessToken: 'access-token',
  uid: 'uid',
  client: 'client',
  geographicKey: 'X-CSCAPI-KEY',
};

// Quantities
export const MAX_CLASSES = 50;

// Types
export const EXERCISE_TYPES = {
  collections: 'collections',
  workouts: 'workouts',
  programs: 'programs',
  classes: 'classes',
  liveClasses: 'live-classes',
  videoDetail: 'video-detail',
};

export const TOAST_TYPES = {
  success: 'success',
  error: 'error',
  info: 'info',
};

export const BRAND_TYPES = {
  singleBrands: 'single_brands',
  allBrands: 'all_brands',
};

export const ACCESS_TYPE_PERM = {
  all: 'all',
  single: 'single',
};

export const SUBSCRIPTIONS_STATUS_TYPES = {
  active: 'active',
  canceled: 'canceled',
};

export const LOADER = {
  TYPES: {
    tail: 'TailSpin',
  },
};

// Dimensions
export const BRAND_ICON_DIMENSIONS = {
  height: 40,
  width: 40,
};

export const SQUARE_24 = {
  height: 24,
  width: 24,
};

export const LOADER_DIMENSIONS = {
  big: {
    height: 100,
    width: 100,
  },
  small: {
    height: 50,
    width: 50,
  },
};

export const PAGE_TITLES = {
  collections: 'Collections',
  workouts: 'All Workouts',
  programs: 'Programs',
  classes: 'Classes',
};

// Stripe
export const STRIPE_INPUTS = {
  cardExpiry: 'cardExpiry',
  cardNumber: 'cardNumber',
  cardCvc: 'cardCvc',
};

export const CHECKOUT_FORM_INPUTS = {
  nameOnCard: 'nameOnCard',
  cardNumber: 'cardNumber',
  expDate: 'expDate',
  cvc: 'cvc',
  street: 'street',
  apt: 'apt',
  zip: 'zip',
  country: 'country',
  state: 'state',
  city: 'city',
};

export const BREAKPOINTS = {
  mobile: 576,
  bigMobile: 375,
  smallMobile: 320,
  tablet: 768,
};

export const OFFER_TYPES = {
  plan: 'plan',
  marketing: 'marketing_offer',
};

export const DEDUPING_INTERVALS = {
  day: 86400000,
};

export const SMALL_SLIDER_BREAKPOINTS = {
  spaceBetween: 20,
  slidesPerView: 1.3,
  breakpoints: {
    [BREAKPOINTS.bigMobile]: {
      slidesPerView: 2.4,
    },
    [BREAKPOINTS.smallMobile]: {
      slidesPerView: 2,
    },
  },
};

export const SLIDER_BREAKPOINTS_REWIND = {
  rewind: true,
  spaceBetween: 20,
  slidesPerView: 1.3,
  breakpoints: {
    [BREAKPOINTS.bigMobile]: {
      slidesPerView: 1.3,
    },
    [BREAKPOINTS.smallMobile]: {
      slidesPerView: 1.1,
    },
    [BREAKPOINTS.mobile]: {
      slidesPerView: 'auto',
    },
  },
};

export const SLIDER_BREAKPOINTS_REWIND_INSTRUCTORS_SECTION = {
  rewind: true,
  spaceBetween: 20,
  slidesPerView: 1.3,
  breakpoints: {
    [BREAKPOINTS.bigMobile]: {
      slidesPerView: 2.5,
    },
    [BREAKPOINTS.smallMobile]: {
      slidesPerView: 2,
    },
    [BREAKPOINTS.mobile]: {
      slidesPerView: 'auto',
    },
  },
};

export const HTTP_METHODS = {
  PUT: 'PUT',
  POST: 'POST',
};

export const REDIRECT_KEYS = {
  signIn: 'onSignInRedirectTo',
  index: 'redirectTo',
  purchase: 'onSuccessPurchase',
  back: 'onBack',
};
