import { goBlue, white } from 'styles/constants/index.module.scss';

export const BUTTON_SORT = {
  primary: 'primary',
  secondary: 'secondary',
  floatingSecondary: 'floating-secondary',
  ghostSecondary: 'ghost-secondary',
};

export const BUTTON_SIZE = {
  medium: 'medium',
  small: 'small',
};

export const BUTTON_LOADER_SIZE = new Map([
  [BUTTON_SIZE.medium, 30],
  [BUTTON_SIZE.small, 20],
]);

export const BUTTON_LOADER_COLOR = new Map([
  [BUTTON_SORT.primary, white],
  [BUTTON_SORT.secondary, goBlue],
  [BUTTON_SORT.floatingSecondary, goBlue],
  [BUTTON_SORT.ghostSecondary, goBlue],
]);
