import {
  SET_EMAIL,
  SET_FOUND_ACCOUNTS,
  SET_ASSOCIATED_ACCOUNTS,
  SET_QUERY_PARAMS,
} from 'state/actions/types/linkedAccountsActionTypes';

export const setLinkedAccountEmail = (email) => ({
  type: SET_EMAIL,
  email,
});

export const setFoundAccounts = (accounts) => ({
  type: SET_FOUND_ACCOUNTS,
  accounts,
});

export const setAssociatedAccounts = (accounts) => ({
  type: SET_ASSOCIATED_ACCOUNTS,
  accounts,
});

export const setQueryParams = (params) => ({
  type: SET_QUERY_PARAMS,
  params,
});
