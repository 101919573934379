import { useMemo } from 'react';

const useGetResultData = ({ emptyState, networkError, normalError }, error) => {
  return useMemo(() => {
    if (!error) return emptyState;
    if (!error.isOnline) return networkError;
    return normalError;
  }, [error, emptyState, networkError, normalError]);
};

export default useGetResultData;
