import { useEffect } from 'react';

import useDebounce from './useDebounce';

const DELAY = 300;

const getOnePercent = (value) => value * 0.01;

const useViewport = () => {
  const updateViewport = useDebounce(() => {
    let vh = getOnePercent(window.innerHeight);
    let vw = getOnePercent(window.innerWidth);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  }, DELAY);

  useEffect(() => {
    window.addEventListener('resize', updateViewport);
    updateViewport(); // TODO review this hook

    () => window.removeEventListener('resize', updateViewport);
  });
};

export default useViewport;
