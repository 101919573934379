import SVGIcons from 'assets';
import { goBlue, white } from 'styles/constants/index.module.scss';

const defaultBrandInfo = {
  friendlyName: 'X+',
  workoutType: '',
  mainColor: goBlue,
  mainTextColor: white,
  secondaryTextColor: goBlue,
  logo: SVGIcons.brands.GoLogoBlack,
  squareLogo: SVGIcons.brands.XLogo,
  whiteLogo: SVGIcons.brands.GoLogo,
  icon: SVGIcons.GoIcon,
};

export default defaultBrandInfo;
