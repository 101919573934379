import { useCallback, useState } from 'react';

const useToggle = (initialState = false) => {
  const [isOn, setIsOn] = useState(initialState);

  const toggle = useCallback(() => setIsOn((prevState) => !prevState), []);

  return { isOn, toggle };
};

export default useToggle;
