import { useReducer } from 'react';

import UiContext from 'context/uiContext';
import uiReducer, { initialState } from 'state/uiReducer';

const UiProvider = ({ children }) => {
  const [state, dispatch] = useReducer(uiReducer, initialState);

  return (
    <UiContext.Provider value={{ state, dispatch }}>
      {children}
    </UiContext.Provider>
  );
};

export default UiProvider;
