import axios from 'axios';

import applyInterceptors from 'httpClient/interceptors';

const APPLICATION_JSON = 'application/json';
const CONTENT_TYPE = 'Content-Type';

const httpClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL_NO_VERSION,
  headers: {
    Accept: APPLICATION_JSON,
    [CONTENT_TYPE]: APPLICATION_JSON,
  },
});

applyInterceptors(httpClient);

export default httpClient;
