import { NextIntlProvider } from 'next-intl';
import { SWRConfig } from 'swr';
import { Provider } from 'next-auth/client';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import sha1 from 'crypto-js/sha1';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import SWR_CONFIG from 'constants/swrConfig';
import BrandProvider from 'components/state/BrandProvider';
import UiProvider from 'components/state/UiProvider';
import LinkedAccountProvider from 'components/state/LinkedAccountProvider';
import TrackingProvider from 'components/state/TrackingProvider';
import BranchSDKProvider from 'components/state/BranchSDKProvider';
import { useViewport } from 'hooks';

import 'styles/globals.scss';
import Script from 'next/script';

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const getLayout = Component.getLayout || ((page) => page);
  const zendeskPages = [
    '/',
    '/account/offers-exclusive',
    '/subscribe/payment-public',
  ];
  useViewport();

  useEffect(() => {
    zendeskPages.includes(router.route)
      ? window.zE('messenger', 'show')
      : window.zE('messenger', 'hide');
  }, [router]);

  const uuid =
    pageProps.session && pageProps.session.user && pageProps.session.user.uuid
      ? pageProps.session.user.uuid
      : '';
  const email =
    pageProps.session && pageProps.session.user && pageProps.session.user.email
      ? sha1(pageProps.session.user.email)
      : '';

  return (
    <>
      <Head>
        <title>Xponential+</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Script
        src="https://script.tapfiliate.com/tapfiliate.js"
        type="text/javascript"
        strategy="beforeInteractive"
      />
      <Script id="tapfiliate-detect" type="text/javascript">
        {`(function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){
        (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');
        tap('create', '17805-c6aeaa');
        tap('detect');`}
      </Script>
      <Script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=6e57989e-c56c-4e7c-bd37-537ca9b3cfb2"
        type="text/javascript"
        strategy="beforeInteractive"
      />
      <Script id="impact" type="text/javascript">
        {`(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};
        f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})
        ('https://utt.impactcdn.com/A4731208-13cb-44b0-b03d-0be177c91b131.js','script','ire',document,window); 
        ire('identify', { customerid: '${uuid}', customeremail: '${email}'});`}
      </Script>

      <NextIntlProvider messages={pageProps.messages}>
        <SWRConfig
          value={{
            fallback: pageProps.fallback || {},
            ...SWR_CONFIG,
          }}
        >
          <Provider session={pageProps.session}>
            <TrackingProvider>
              <BranchSDKProvider>
                <LinkedAccountProvider>
                  <UiProvider>
                    <BrandProvider>
                      {getLayout(
                        <Component key={router.asPath} {...pageProps} />
                      )}
                      <div id="portal" />
                    </BrandProvider>
                  </UiProvider>
                </LinkedAccountProvider>
              </BranchSDKProvider>
            </TrackingProvider>
          </Provider>
        </SWRConfig>
      </NextIntlProvider>
    </>
  );
}

export default MyApp;
