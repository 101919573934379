import { useMemo } from 'react';
import useSwrHook from 'swr';
import { useSession } from 'next-auth/client';

import getAuthHeadersFromSession from 'utils/auth/getAuthHeadersFromSession';

const getKey = (key, config, loadingSession) => {
  // If session is still loading no queries should be performed
  if (loadingSession) return null;

  // If a function was provided as a key It should be evaluated to get the actual url.
  if (typeof key === 'function') {
    key = key();
  }

  // If no key is provided, no query is performed.
  if (!key) return null;

  // returning an array which contains key along auth headers, it will be used by useSWR as the final key set.
  return [key, ...config];
};

const useSWR = (key, options) => {
  const [session, loading] = useSession();
  const config = useMemo(
    () => getAuthHeadersFromSession(session),
    /* eslint-disable-next-line react-hooks/exhaustive-deps*/
    [session?.user?.accessToken]
  );
  const { data, error, ...rest } = useSwrHook(
    getKey(key, config, loading),
    options
  );
  return { data, error, isLoading: !data && !error, ...rest };
};

export default useSWR;
