import { string } from 'prop-types';

const FilterIcon = ({ color = 'current', fill = 'current', ...props }) => {
  return (
    <svg
      width={20}
      height={20}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 10h10M1.875 6.25h16.25M8.125 13.75h3.75"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

FilterIcon.propTypes = {
  color: string,
  fill: string,
};

export default FilterIcon;
