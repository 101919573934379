import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

/*
 * @param options: {
 *    fallbackTimeout: number -> time to wait before consider something went wrong with the deep link,
 *    onReturn: () => void -> callback to call when the user returns to the app,
 *    onFallback: () => void -> callback to call when something went wrong with the deep link,
 *  }
 */

const defaultOptions = {
  fallbackTimeout: 2500,
};

export const useDeepLink = (userOptions) => {
  const options = useMemo(
    () => ({ ...defaultOptions, ...userOptions }),
    [userOptions]
  );

  const [hasFocus, setHasFocus] = useState(true);
  const [didHide, setDidHide] = useState(false);

  const onBlur = useCallback(() => {
    setHasFocus(false);
  }, []);

  const onVisibilityChange = useCallback((event) => {
    if (event.target.visibilityState === 'hidden') {
      setDidHide(true);
    }
  }, []);

  const onFocus = useCallback(() => {
    if (didHide) {
      options.onReturn?.();

      setDidHide(false);
    }

    setHasFocus(true);
  }, [didHide, options]);

  const setupListeners = useCallback(
    (mode) => {
      const listeners = [
        { element: window, event: 'blur', handler: onBlur },
        {
          element: document,
          event: 'visibilitychange',
          handler: onVisibilityChange,
        },
        { element: window, event: 'focus', handler: onFocus },
      ];

      listeners.forEach(({ element, event, handler }) => {
        element[mode + 'EventListener'](event, handler);
      });
    },
    [onBlur, onFocus, onVisibilityChange]
  );

  useLayoutEffect(() => {
    setupListeners('add');

    return () => {
      setupListeners('remove');
    };
  }, [setupListeners]);

  const open = useCallback(
    (url) => {
      setTimeout(() => {
        if (hasFocus && options.onFallback) {
          options.onFallback();
        }
      }, options.fallbackTimeout);

      window.location = url;
    },
    [hasFocus, options]
  );

  return { open };
};

const ANDROID = 'android';
const IOS = 'ios';
const WEB = 'web';

export const isAndroid = () => {
  return navigator.userAgent.indexOf('Android') !== -1;
};

export const isIOS = () => {
  return navigator.userAgent.indexOf('iPhone') !== -1;
};

export const getPlatform = () => {
  if (isIOS()) {
    return IOS;
  }

  if (isAndroid()) {
    return ANDROID;
  }

  return WEB;
};
