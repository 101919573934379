export const ROUTES = {
  home: '/',
  classes: '/classes',
  workouts: '/classes/workouts',
  collections: '/classes/collections',
  programs: '/classes/programs',
  schedule: '/schedule',
  studios: '/studios',
  videoDetail: '/video-detail',
  login: '/login',
  emailLookup: '/email-lookup',
  signupInformation: '/signup-information',
  liveClassDetail: '/live-class-detail',
  fullScreen: '/live-class-detail/fullscreen',
  forgotPassword: '/forgot-password',
  subscriptionPayment: '/subscribe/payment',
  subscriptionPaymentPublic: '/subscribe/payment-public',
  matchingAccount: '/subscribe/matching-account',
  verifyCode: '/subscribe/verify-code',
  associatedAccounts: '/subscribe/associated-accounts',
  subscriptionSuccess: '/subscribe/subscription-success',
  subscriptionSuccessPublic: '/subscribe/subscription-success-public',
  subscriptionEntitlement: '/subscribe/entitlement',
  subscriptionPlanSelector: '/subscription-plan-selector',
  singleBrandSelector: '/single-brand-selector',
  information: '/account/profile',
  favorites: '/account/favorites',
  favoriteList: '/account/favorites/list',
  subscriptions: '/account/subscriptions',
  subscriptionSingleBrand: '/account/subscriptions/select-brand',
  linkedAccounts: '/account/linked-accounts',
  bookings: '/bookings',
  billingInformation: '/account/billing',
  plans: '/account/plans',
  instructors: '/instructors',
  offers: '/account/offers',
  activity: '/account/activity',
  activityByDate: '/account/activity/by-date',
  activityInStudio: '/account/activity/in-studio',
  offersExclusive: '/account/offers-exclusive',
  xponentialShop: process.env.NEXT_PUBLIC_SHOP_LINK,
  activationCode: '/account/activation-code',
  subscribeSPC: '/subscribe/payment-public',
};
