import { useMemo } from 'react';
import flatMap from 'lodash/flatMap';

const useInfinitePagination = ({ data, size, error }) => {
  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined');

  const isEmpty = data?.[0]?.data?.length === 0;
  const isReachingEnd =
    isEmpty || !(data && data[data.length - 1].paging?.nextPage);
  const renderShowMoreButton = !isReachingEnd;

  const gridData = useMemo(() => flatMap(data, (n) => n.data), [data]);

  return {
    isLoadingInitialData,
    isLoadingMore,
    renderShowMoreButton,
    gridData,
    isEmpty,
  };
};

export default useInfinitePagination;
