import cn from 'classnames/bind';

import { useToggle } from 'hooks';

import styles from 'styles/effects/fade.module.scss';

const style = cn.bind(styles);

const useFade = (isOpen = false) => {
  const { isOn: isShown, toggle } = useToggle(false);

  const fadeClassName = style({
    open: isOpen,
    closed: !isOpen,
    hidden: !isOpen && !isShown,
  });

  return { fadeClassName, onAnimationEnd: toggle };
};

export default useFade;
