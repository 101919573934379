import { ROUTES } from './index';

export const PATH_LABELS = new Map([
  [ROUTES.home, 'home'],
  [ROUTES.classes, 'classes'],
  [ROUTES.schedule, 'schedule'],
  [ROUTES.studios, 'studios'],
  [ROUTES.workouts, 'workouts'],
  [ROUTES.programs, 'programs'],
  [ROUTES.login, 'login'],
  [ROUTES.emailLookup, 'emailLookup'],
  [ROUTES.signupInformation, 'signup'],
  [ROUTES.information, 'profile'],
  [ROUTES.favorites, 'favorites'],
  [ROUTES.subscriptions, 'subscriptions'],
  [ROUTES.subscriptionPayment, 'payment'],
  [ROUTES.subscriptionPaymentPublic, 'paymentPublic'],
  [ROUTES.subscriptionSuccess, 'paymentSuccess'],
  [ROUTES.subscriptionSuccessPublic, 'paymentSuccessPublic'],
  [ROUTES.subscriptionEntitlement, 'entitlementSuccess'],
  [ROUTES.bookings, 'bookings'],
  [ROUTES.collections, 'collections'],
  [ROUTES.billingInformation, 'billingInformation'],
  [ROUTES.plans, 'plans'],
  [ROUTES.subscriptionPlanSelector, 'selectOffer'],
  [ROUTES.linkedAccounts, 'linkedAccounts'],
  [ROUTES.instructors, 'instructors'],
  [ROUTES.offers, 'offers'],
  [ROUTES.activity, 'activity'],
  [ROUTES.activityByDate, 'activityByDate'],
  [ROUTES.activityInStudio, 'activityInStudio'],
  [ROUTES.offersExclusive, 'offersExclusive'],
  [ROUTES.xponentialShop, 'shop'],
  [ROUTES.activationCode, 'activationCode'],
  [process.env.NEXT_PUBLIC_SUPPORT_LINK, 'faq'],
  [process.env.NEXT_PUBLIC_REFER_FRIEND_LINK, 'referFriend'],
]);

export const HOME_SUBPATH = new Map([[ROUTES.home, true]]);

export default PATH_LABELS;
