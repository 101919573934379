import cn from 'classnames/bind';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { bool, func, string, object, node } from 'prop-types';

import Button from 'components/common/Button';
import { BUTTON_SORT } from 'constants/buttonConstants';
import SVGIcons from 'assets';
import { useFade } from 'hooks';

import styles from 'styles/components/modal.module.scss';

const style = cn.bind(styles);

const Modal = ({
  isOn,
  toggle,
  title,
  description,
  leftBtnLabel,
  rightBtnLabel,
  onLeftClick,
  onRightClick,
  iconSrc,
  iconAlt,
  footerClassName,
  onCloseClick,
  onContainerClick = null,
  onOverlayClick = null,
  titleClassName = '',
  descriptionClassName = '',
  showCloseIcon = true,
  children,
  containerClassName = '',
}) => {
  const translations = useTranslations('Modal');
  const containerStyle = style({
    container: true,
    open: isOn,
    fadeClassName: true,
    [containerClassName]: true,
  });

  const { fadeClassName, onAnimationEnd } = useFade(isOn);

  const titleStyle = style({
    [titleClassName]: true,
    title: !titleClassName,
    'h2-bold-24': !titleClassName,
  });

  const descritpionStyle = style({
    [descriptionClassName]: true,
    description: !descriptionClassName,
    'p-regular-16 gray': !descriptionClassName,
  });

  return (
    <>
      {isOn && <div className={styles.overlay} onClick={onOverlayClick} />}
      <div
        className={`${containerStyle} ${fadeClassName}`}
        onAnimationEnd={onAnimationEnd}
        onClick={onContainerClick}
      >
        {showCloseIcon && (
          <div className={styles.closeIcon}>
            <Image
              src={SVGIcons.CrossIcon}
              alt={translations('alt.close')}
              onClick={onCloseClick || toggle}
              unoptimized
            />
          </div>
        )}
        {children ? (
          children
        ) : (
          <>
            <span className={titleStyle}>{title}</span>
            <span className={descritpionStyle}>{description}</span>

            {iconSrc && (
              <div className={styles.imageContainer}>
                <Image src={iconSrc} alt={iconAlt} unoptimized />
              </div>
            )}
            <div className={`${styles.footer} ${footerClassName}`}>
              {leftBtnLabel && (
                <Button
                  label={leftBtnLabel}
                  sort={BUTTON_SORT.secondary}
                  overrides={{
                    ButtonElem: { className: styles.leftBtn },
                  }}
                  onClick={onLeftClick}
                />
              )}
              {rightBtnLabel && (
                <Button
                  label={rightBtnLabel}
                  overrides={{
                    ButtonElem: { className: styles.rightBtn },
                  }}
                  onClick={onRightClick}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

Modal.propTypes = {
  isOn: bool.isRequired,
  toggle: func.isRequired,
  leftBtnLabel: string,
  rightBrtLabel: string,
  onLeftClick: func,
  onRightClick: func,
  iconSrc: object,
  iconAlt: string,
  children: node,
  onContainerClick: func,
  onOverlayClick: func,
};

export default Modal;
