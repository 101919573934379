import { useState } from 'react';
import { useSession } from 'next-auth/client';
import { useTranslations } from 'next-intl';

import httpClient from 'httpClient';
import SERVICES from 'constants/services';

const useBooking = () => {
  const [session] = useSession();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [isSuccess, setIsSuccess] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const translations = useTranslations('BookButton');

  const headers = {
    'access-token': session?.user?.accessToken,
    client: session?.user?.client,
    uid: session?.user?.uid,
  };

  const bookLiveClass = async (liveClassId, toggleSubscribeNow, mutate) => {
    try {
      setIsLoading(true);
      setError('');
      const { error: requestError } = await httpClient.request({
        headers,
        url: SERVICES.bookings,
        method: 'POST',
        data: {
          booking: {
            live_class_id: liveClassId,
          },
        },
      });

      setIsLoading(false);
      if (requestError) {
        setError(requestError);
      } else {
        setIsSuccess(true);
        setSuccessMessage(translations('toast.success.descriptions.book'));
        mutate();
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 403) {
        toggleSubscribeNow();
        return;
      }
      const data = error.response?.data;
      const fullMessages = data?.errors?.full_messages;
      if (fullMessages) setError(fullMessages);
      else if (data?.error) setError(data?.error);
      else setError(translations('errors.default'));
    }
  };

  const cancelBooking = async (bookingId, mutate) => {
    try {
      setIsLoading(true);
      setError('');
      const { data } = await httpClient.request({
        headers,
        url: `${SERVICES.bookings}/${bookingId}`,
        method: 'DELETE',
      });
      setIsLoading(false);
      if (data.error) setError(data.error);
      else {
        setSuccessMessage(translations('toast.success.descriptions.cancel'));
        setIsSuccess(true);
        mutate();
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response?.data?.errors?.full_messages)
        setError(error.response.data.errors.full_messages);
      else if (error.response?.data?.error) setError(error.response.data.error);
      else setError('Something went wrong');
    }
  };
  return {
    isLoading,
    error,
    setError,
    isSuccess,
    setIsSuccess,
    successMessage,
    setSuccessMessage,
    bookLiveClass,
    cancelBooking,
  };
};

export default useBooking;
