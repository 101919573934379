const SERVICES = {
  videos: '/v1/videos',
  brands: '/v1/brands',
  contactStudios: '/v1/free_class_requests',
  studios: '/v1/studios',
  categories: '/v1/modalities',
  equipment: '/v1/equipment',
  bodyFocuses: '/v1/body_focuses',
  instructors: '/v1/instructors',
  login: '/v1/users/sign_in',
  users: '/v1/users',
  user: '/v1/user',
  liveClasses: '/v1/live_classes',
  password: '/v1/users/password',
  subscription: '/v1/subscriptions/web',
  legalAgreements: '/v1/settings/legal_agreements',
  subscriptions: '/v1/subscriptions',
  verifiedUserLookups: '/v1/verified_user_lookups',
  userLookups: '/v1/user_lookups',
  checkEmailVerification: '/v1/verify',
  brightcoveToken: '/v1/brightcove_token/generate',
  bookings: '/v1/bookings',
  subscriptionPlan: '/v1/subscription_plan',
  subscriptionPlanPublic: '/v1/public/plan',
  subscriptionEntitlement: '/v1/enterprise_provider_entitlements',
  enterpriseProvider: '/v1/enterprise_provider',
  collections: '/v1/video_groups/collections',
  programs: '/v1/video_groups/programs',
  paymentProfile: '/v1/payment_profile',
  paymentProfiles: '/v1/payment_profiles',
  associatedAccounts: '/v1/associated_users',
  invoices: '/v1/invoices',
  verifyPromoCode: '/v1/promotion_codes',
  verifyPromoCodePublic: '/v1/public/promotion_codes',
  videoWatches: '/v2/user_video_watches',
  plans: '/v1/plans/web',
  offers: '/v1/offers/web',
  offersPublic: '/v1/public/offers/web',
  onboardingOffers: '/v1/onboarding_offers/web',
  onboardingOffersPublic: '/v1/public/onboarding_offers/web',
  homeOffers: '/v1/homepage_offers/web',
  userOffers: '/v1/user_offers/web',
  profilePictures: '/v1/profile_pictures',
  adTags: '/v1/settings/ad_tags',
  userActivities: '/v1/user_activities',
  userVideoSessions: '/v1/user_video_sessions',
  favoriteList: '/v1/favorite_lists',
  authGrants: '/v1/applicaster_auth_grants',
  studioBookings: '/v1/studio_bookings',
};

export const GEOGRAPHIC_SERVICES = {
  countries: '/countries',
};

export default SERVICES;
