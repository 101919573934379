import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { ROUTES } from 'constants/routePaths';

const useOnBackNavigation = () => {
  const router = useRouter();
  const onBackRedirection = router.query.onBack;
  const [onBackFunc, setOnBackFunc] = useState(null);
  const [stackIndex, setStackIndex] = useState(null);

  useEffect(() => {
    setStackIndex(window?.history.length > 2);
  }, []);

  useEffect(() => {
    setOnBackFunc(() => {
      if (onBackRedirection) return () => router.push(onBackRedirection);
      else if (stackIndex) return router.back;
      return () => router.push(ROUTES.home);
    });
  }, [stackIndex, onBackRedirection, router]);

  return onBackFunc;
};

export default useOnBackNavigation;
