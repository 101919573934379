import { identify } from 'utils/tracking';
import { useSession } from 'next-auth/client';
import { useTracking } from 'react-tracking';
import { useEffect } from 'react';

const useTrackingWithUser = (props = {}) => {
  const [session] = useSession();
  const tracking = useTracking({ ...props, user: session?.user });

  useEffect(() => {
    if (session?.user) {
      identify(session.user);
    }
  }, [session]);

  return tracking;
};

export default useTrackingWithUser;
