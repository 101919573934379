import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/client';

import { AUTH_HEADERS, HTTP_METHODS } from 'constants';
import SERVICES from 'constants/services';
import httpClient from 'httpClient';

const useTrackJoinedClass = () => {
  const [session] = useSession();

  const trackJoinedClass = useCallback(
    async ({ bookingId }) => {
      await httpClient.request({
        headers: {
          [AUTH_HEADERS.accessToken]: session?.user?.accessToken,
          [AUTH_HEADERS.client]: session?.user?.client,
          [AUTH_HEADERS.uid]: session?.user?.uid,
        },
        url: `${SERVICES.bookings}/${bookingId}`,
        method: HTTP_METHODS.PUT,
        data: {
          attended: true,
        },
      });
    },
    [session?.user?.accessToken, session?.user?.client, session?.user?.uid]
  );

  return trackJoinedClass;
};

export default useTrackJoinedClass;
